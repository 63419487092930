define("adt-wss/pods/components/sierra-chat-include/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentLocation: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments); // Update immediately on insert, in case profileInfo already loaded


      this._updateChatToken();

      this.currentLocation.on('profileInfoLoaded', this, this._updateChatToken);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.currentLocation.off('profileInfoLoaded', this, this._updateChatToken);
    },

    _updateChatToken() {
      const profileInfo = this.get('currentLocation.profileInfo');
      const token = profileInfo && profileInfo.sierraJwtToken ? profileInfo.sierraJwtToken : '';
      const btn = this.element.querySelector('.sierra-chat-btn');

      if (btn) {
        btn.setAttribute('data-auth-token', token);
        console.log("✅ Sierra JWT token set on button:", token);
      } else {
        console.warn("⚠️ Sierra chat button not found in DOM.");
      }
    },

    actions: {
      toggleChatWindow() {
        console.log("Chat window open/closed");
      }

    }
  });

  _exports.default = _default;
});